export function randColor() {
    let s = '#';
    for (let i = 0; i < 3; i += 1) {
        const c = Math.floor(Math.random() * 16);
        switch (c) {
            case 10:
                s += 'a';
                break;
            case 11:
                s += 'b';
                break;
            case 12:
                s += 'c';
                break;
            case 13:
                s += 'd';
                break;
            case 14:
                s += 'e';
                break;
            case 15:
                s += 'f';
                break;
            default:
                s += c;
                break;
        }
    }
    return s;
}
