import { Keyframes } from '@emotion/react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';

import './Avatar.component.css';

const animationDuration = '1000ms';

const jumpKeyframes = (move: number[]) => keyframes`
    from {
        left: ${move[0]}px;
        top: ${move[1]}px;
    }
    to {
        left: ${move[2]}px;
        top: ${move[3]}px;
    }
`;

const tiltKeyframes = keyframes`
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
`;

const shadowKeyframes = keyframes`
    0% {
        transform: translateX(0px) translateY(0px);
    }
    50% {
        transform: translateX(-50px) translateY(-50px);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
`;

type ComponentProps = {
    overlay?: HTMLDivElement;
    jumpTo?: HTMLElement;
};

export function Avatar({ overlay, jumpTo }: ComponentProps) {
    const avaterRef = useRef<HTMLDivElement>(null);
    const [inside, setInside] = useState<HTMLElement>();
    const [jump, setJump] = useState<Keyframes>();

    useEffect(() => {
        if (inside !== jumpTo) {
            if (!!jumpTo && !!avaterRef.current && !!overlay) {
                const endListener = (event: AnimationEvent) => {
                    if (tiltKeyframes.name === event.animationName) {
                        setJump(undefined);
                        jumpTo.append(avaterRef.current);
                        overlay.removeEventListener('animationend', endListener);
                    }
                };
                overlay.addEventListener('animationend', endListener);
                overlay.append(avaterRef.current);
                overlay.style.display = 'block';
                const overlayRect = overlay.getBoundingClientRect();
                const from = inside
                    ? inside.getBoundingClientRect()
                    : { left: overlayRect.left, top: overlayRect.top, height: overlayRect.height, width: 0 };
                const to = jumpTo.getBoundingClientRect();
                const moveFromTo = [
                    from.left + from.width / 2 - overlayRect.left,
                    from.top + from.height / 2 - overlayRect.top,
                    to.left + to.width / 2 - overlayRect.left,
                    to.top + to.height / 2 - overlayRect.top,
                ];
                setJump(jumpKeyframes(moveFromTo));
            } else if (jumpTo) {
                jumpTo.append(avaterRef.current);
            } else {
                overlay.append(avaterRef.current);
            }
            setInside(jumpTo);
        }
    }, [jumpTo, overlay, avaterRef, setInside, inside]);

    return (
        <Box
            className="avatar"
            ref={avaterRef}
            sx={{
                display: jumpTo ? 'block' : 'none',
                animation: jump !== undefined ? `${jump} ${animationDuration} 1 ease-in-out` : 'none',
            }}
        >
            <Box
                className="avatar-shadow"
                sx={{
                    animation: jump !== undefined ? `${shadowKeyframes} ${animationDuration} 1 ease-in-out` : 'none',
                }}
            ></Box>
            <Box
                className="avatar-img"
                sx={{
                    animation: jump !== undefined ? `${tiltKeyframes} ${animationDuration} 1 ease-in-out` : 'none',
                }}
            ></Box>
        </Box>
    );
}
