import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App';
import { setupI18n } from './i18n';

setupI18n();

const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
