export function getFontColor(c: string | undefined): 'black' | 'white' {
    if (!c) {
        throw new Error('cell color is undefined');
    }
    if (c.charAt(0) === '#') {
        return (parseInt(c.charAt(1), 16) +
            parseInt(c.charAt(c.length === 7 ? 3 : 2), 16) +
            parseInt(c.charAt(c.length === 7 ? 5 : 3), 16)) /
            3 <
            8
            ? 'white'
            : 'black';
    }
    return 'black';
}
