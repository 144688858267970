import EventEmitter from 'events';

import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Divider, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
    open: boolean;
    onClose: () => void;
    downloadEvents: () => void;
    downloadLayout: () => void;
    clearEvents: () => void;
    action: EventEmitter;
};

export function MaintenanceDialog({ open, onClose, downloadEvents, downloadLayout, clearEvents, action }: ComponentProps) {
    const { t } = useTranslation();

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle sx={{ m: 0, mr: 6, p: 2 }}>
                {t('maintenance.dialog.title')}{' '}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItemButton
                    data-testid="export.csv"
                    onClick={() => {
                        downloadEvents();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('export.csv')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="clear.csv"
                    onClick={() => {
                        clearEvents();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <CleaningServicesIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('clear.csv')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="import.csv"
                    onClick={() => {
                        action.emit('importEvents');
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('import.csv')} />
                </ListItemButton>
            </List>
            <Divider />
            <List sx={{ pt: 0 }}>
                <ListItemButton
                    data-testid="export.layout"
                    onClick={() => {
                        downloadLayout();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('export.layout')} />
                </ListItemButton>
                <ListItemButton
                    data-testid="import.layout"
                    onClick={() => {
                        action.emit('importLayout');
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <FileUploadIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('import.layout')} />
                </ListItemButton>
            </List>
        </Dialog>
    );
}
