import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TablePagination,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import { format, startOfMonth, subMonths, endOfMonth, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { ConnectionMode } from '../connect/connection-mode';
import { Sync } from '../connect/sync';
import { csvTable } from '../utils/csv-utils';
import {
    KEY_CONNECTIONS,
    KEY_REPORT_COLORS,
    KEY_REPORT_GROUP,
    KEY_REPORT_INFOS,
    KEY_REPORT_QUANTISIZE,
} from '../utils/locale.storage.keys';
import { getEntries } from '../utils/reporting-utils';
import { getJiraDuration, getQuantisized } from '../utils/timeline-utils';
import { useConfig } from '../utils/use-config';
import { useIdleRefocus } from '../utils/use-idle-refocus';

import { Entry } from './entry';
import { Group } from './group';
import { Head } from './Head';
import { Quantisize } from './quantisize';
import { Row } from './Row';

export function Reporting() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const viewRef = useRef<HTMLDivElement>(null);
    const topBarRef = useRef<HTMLElement>(null);
    const bottomBarRef = useRef<HTMLElement>(null);
    const { onChange, clearIdle } = useIdleRefocus();
    const [sync] = useConfig<Sync>(KEY_CONNECTIONS, { pairings: {}, version: 0 });
    const messageBar = useSnackbar();

    const [saveNow, lastMonthStart, lastMonthEnd, thisMonthStart, thisMonthEnd] = useMemo(() => {
        const saveNow = new Date().getTime();
        const thisMonthStart = startOfMonth(saveNow);
        const thisMonthEnd = endOfMonth(saveNow);
        const lastMonthStart = startOfMonth(subMonths(saveNow, 1));
        const lastMonthEnd = endOfMonth(subMonths(saveNow, 1));
        return [saveNow, lastMonthStart, lastMonthEnd, thisMonthStart, thisMonthEnd];
    }, []);

    const params = useParams();
    let initFrom = lastMonthStart;
    if (params.from) {
        initFrom = parseISO(params.from);
    }
    let initTo = lastMonthEnd;
    if (params.to) {
        initTo = parseISO(params.to);
    }
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [headerHeight, setHeaderHeight] = useState(5);
    const [rowHeight, setRowHeight] = useState(5);

    const [from, setFrom] = useState(initFrom);
    const [to, setTo] = useState(initTo);
    const [group, setGroup] = useConfig(KEY_REPORT_GROUP, Group.PER_DAY);
    const [quantisize, setQuantisize] = useConfig(KEY_REPORT_QUANTISIZE, '' + Quantisize.MINUTES);
    const [filterExpanded, setFilterExpanded] = useState(false);
    const [includeInfos, setIncludeInfos] = useConfig(KEY_REPORT_INFOS, 'off');
    const [includeColors, setIncludeColors] = useConfig(KEY_REPORT_COLORS, 'off');

    const navigate = useNavigate();
    const { ref } = useSwipeable({ onSwipedRight: () => navigate('/') }) as unknown as { ref: RefCallback<Document> };

    const entries: Entry[] = useMemo(
        () => getEntries(from, to, saveNow, group, includeInfos === 'on', includeColors === 'on'),
        [to, from, saveNow, group, includeInfos, includeColors]
    );

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setRowHeight(10);
    };

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.target['tagName'] === 'INPUT') {
                return;
            }
            switch (event.key) {
                case 'ArrowLeft':
                    if (page > 0) {
                        setPage(page - 1);
                    }
                    break;
                case 'ArrowRight':
                    if (page < Math.floor(entries.length / rowsPerPage)) {
                        setPage(page + 1);
                    }
                    break;
                case 'ArrowUp':
                    setPage(0);
                    break;
                case 'ArrowDown':
                    setPage(Math.floor(entries.length / rowsPerPage));
                    break;
            }
        },
        [page, setPage, entries, rowsPerPage]
    );

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyUp]);

    useEffect(() => {
        setPage(0);
        setRowHeight(10);
        ref(document);
        return () => {
            ref({} as Document);
        };
    }, [entries, setPage, ref]);

    const resizingDelayTimer = useRef(null);
    useEffect(() => {
        const topBar = topBarRef.current;
        const bottomBar = bottomBarRef.current;
        const observer = new ResizeObserver(() => {
            clearTimeout(resizingDelayTimer.current);
            resizingDelayTimer.current = setTimeout(() => {
                if (topBar && bottomBar && includeInfos !== 'on') {
                    const height = viewRef.current?.offsetHeight - topBar?.offsetHeight - bottomBar?.offsetHeight - headerHeight;
                    const result = Math.max(1, Math.floor(height / rowHeight));
                    console.log('height: ' + height + ' rowHeight: ' + rowHeight + ' result: ' + result);
                    setRowsPerPage(result);
                }
            }, 200);
        });
        observer.observe(topBar);
        observer.observe(bottomBar);
        return () => {
            if (observer && topBar) {
                observer.unobserve(topBar);
            }
            if (observer && bottomBar) {
                observer.unobserve(bottomBar);
            }
        };
    }, [setRowsPerPage, headerHeight, rowHeight, includeInfos]);

    const downloadReport = useCallback(() => {
        const blob = csvTable(
            entries.map((row) => [
                format(new Date(row.start), t('date.format')),
                row.name,
                ...(includeColors === 'on' ? [row.color] : []),
                ...(includeInfos === 'on' ? [row.info] : []),
                row.time ? getJiraDuration(24 * 7, 24, getQuantisized(+quantisize, row.time)) : '',
                row.sum ? getJiraDuration(24 * 7, 24, getQuantisized(+quantisize, row.sum)) : '',
            ]),
            [
                t('reporting.date'),
                t('reporting.name'),
                ...(includeColors === 'on' ? [t('reporting.color')] : []),
                ...(includeInfos === 'on' ? [t('reporting.info')] : []),
                t('reporting.time'),
                t('reporting.sum'),
            ]
        );
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'time-emphasize_report_' + format(from, t('date.format')) + '-' + format(to, t('date.format')) + '.csv');
        a.click();
    }, [t, from, to, entries, quantisize, includeColors, includeInfos]);

    const generateInvoices = useCallback(
        async (hostPath: string) => {
            const token = (+('' + Math.random()).replace(/[,.]/g, '')).toString(36);
            const payload = {
                token,
                report: {
                    from,
                    to,
                    lang: i18n.language,
                    includeColors,
                    includeInfos,
                    group,
                    quantisize,
                },
                entries,
            };
            const response = await fetch((hostPath.includes('://') ? '' : 'http://') + hostPath, {
                body: JSON.stringify(payload),
                method: 'POST',
                keepalive: true,
            });
            if (response.status === 200) {
                const a = document.createElement('a');
                a.setAttribute('href', (hostPath.includes('://') ? '' : 'http://') + `${hostPath}?t=${token}`);
                a.click();
                messageBar.enqueueSnackbar(t('success') + ': ' + t('sync.add.invoice'), { variant: 'success' });
            } else {
                messageBar.enqueueSnackbar(t('failed') + ': ' + t('sync.add.invoice') + ' ' + '(' + response.statusText + ')', {
                    variant: 'warning',
                });
            }
        },
        [from, to, i18n, includeColors, includeInfos, group, quantisize, entries, messageBar, t]
    );

    const resolveGroupSum = (value: Group) => {
        switch (value) {
            case Group.OVERALL:
                return t('reporting.group.overall');
            case Group.PER_DAY:
                return t('reporting.group.perDay');
            case Group.PER_WEEK:
                return t('reporting.group.perWeek');
            case Group.PER_MONTH:
                return t('reporting.group.perMonth');
            case Group.PER_YEAR:
                return t('reporting.group.perYear');
            default:
                throw new Error('unknown group value ' + value);
        }
    };

    const resolveQuantisizeSum = (value: Quantisize) => {
        switch (value) {
            case Quantisize.NONE:
                return t('reporting.quantisize.none');
            case Quantisize.SECONDS:
                return t('reporting.quantisize.seconds');
            case Quantisize.MINUTES:
                return t('reporting.quantisize.minutes');
            case Quantisize.FIVE_MINUTES:
                return t('reporting.quantisize.fiveMinutes');
            case Quantisize.TEN_MINUTES:
                return t('reporting.quantisize.tenMinutes');
            case Quantisize.FIFTEEN_MINUTES:
                return t('reporting.quantisize.fifteenMinutes');
            case Quantisize.HALF_HOURS:
                return t('reporting.quantisize.halfHours');
            case Quantisize.HOURS:
                return t('reporting.quantisize.hours');
            default:
                throw new Error('unknown quantisize value ' + value);
        }
    };
    return (
        <Box
            ref={viewRef}
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
            }}
        >
            <AppBar ref={topBarRef} position="static" color="inherit" sx={{ zIndex: 1 }}>
                <Accordion style={{ boxShadow: 'none' }} expanded={filterExpanded} onChange={() => setFilterExpanded(!filterExpanded)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon color="primary" />}
                        data-testid="report.header"
                        aria-controls="tabs-filter-content"
                        id="tabs-filter-header"
                        sx={{ margin: 0, padding: 0 }}
                    >
                        {from !== lastMonthStart && to !== lastMonthEnd ? (
                            <Button
                                sx={{ margin: '0 1rem' }}
                                size="small"
                                variant="outlined"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    setFrom(lastMonthStart);
                                    setTo(lastMonthEnd);
                                    e.stopPropagation();
                                }}
                            >
                                {t('reporting.quick.lastMonth')}
                            </Button>
                        ) : (
                            <Button
                                sx={{ margin: '0 1rem' }}
                                size="small"
                                variant="outlined"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    setFrom(thisMonthStart);
                                    setTo(thisMonthEnd);
                                    e.stopPropagation();
                                }}
                            >
                                {t('reporting.quick.thisMonth')}
                            </Button>
                        )}
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                            {format(from, t('date.format')) + ' - ' + format(to, t('date.format'))}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                            {t('reporting.group.label') + ' ' + resolveGroupSum(group)}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                            {t('reporting.quantisize.label') + ' ' + resolveQuantisizeSum(+quantisize)}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                            {includeInfos === 'on' ? t('reporting.include.infos') : ''}
                        </Typography>
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                            {includeColors === 'on' ? t('reporting.include.colors') : ''}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                            <Box sx={{ padding: '8px 1em 0 1em' }}>
                                <DatePicker
                                    label={t('reporting.from.date')}
                                    format={t('date.format')}
                                    value={from}
                                    maxDate={to}
                                    onChange={(newValue) => {
                                        setFrom(newValue);
                                    }}
                                />
                            </Box>
                            <Box sx={{ padding: '8px 1em 0 1em' }}>
                                <DatePicker
                                    label={t('reporting.to.date')}
                                    format={t('date.format')}
                                    value={to}
                                    minDate={from}
                                    onChange={(newValue) => {
                                        setTo(newValue);
                                    }}
                                />
                            </Box>
                            <Box sx={{ padding: '0 1em' }}>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="group-label">{t('reporting.group.label')}</InputLabel>
                                    <Select
                                        data-testid="report.group"
                                        labelId="group-label"
                                        id="group"
                                        defaultValue={group ?? Group.PER_DAY}
                                        label={t('reporting.group.label')}
                                        onChange={onChange}
                                        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            clearIdle();
                                            setGroup(e.target.value as Group);
                                        }}
                                    >
                                        <MenuItem value={Group.OVERALL}>
                                            <em>{t('reporting.group.overall')}</em>
                                        </MenuItem>
                                        <MenuItem value={Group.PER_DAY}>{t('reporting.group.perDay')}</MenuItem>
                                        <MenuItem value={Group.PER_WEEK}>{t('reporting.group.perWeek')}</MenuItem>
                                        <MenuItem value={Group.PER_MONTH}>{t('reporting.group.perMonth')}</MenuItem>
                                        <MenuItem value={Group.PER_YEAR}>{t('reporting.group.perYear')}</MenuItem>
                                    </Select>
                                    <FormHelperText>{t('reporting.group.hint')}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{ padding: '0 1em' }}>
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="quantisize-label">{t('reporting.quantisize.label')}</InputLabel>
                                    <Select
                                        data-testid="report.quantisize"
                                        labelId="quantisize-label"
                                        id="quantisize"
                                        defaultValue={quantisize ?? Quantisize.MINUTES}
                                        label={t('reporting.quantisize.label')}
                                        onChange={onChange}
                                        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            clearIdle();
                                            setQuantisize(e.target.value as Group);
                                        }}
                                    >
                                        <MenuItem value={Quantisize.NONE}>
                                            <em>{t('reporting.quantisize.none')}</em>
                                        </MenuItem>
                                        <MenuItem value={Quantisize.SECONDS}>{t('reporting.quantisize.seconds')}</MenuItem>
                                        <MenuItem value={Quantisize.MINUTES}>{t('reporting.quantisize.minutes')}</MenuItem>
                                        <MenuItem value={Quantisize.FIVE_MINUTES}>{t('reporting.quantisize.fiveMinutes')}</MenuItem>
                                        <MenuItem value={Quantisize.TEN_MINUTES}>{t('reporting.quantisize.tenMinutes')}</MenuItem>
                                        <MenuItem value={Quantisize.FIFTEEN_MINUTES}>{t('reporting.quantisize.fifteenMinutes')}</MenuItem>
                                        <MenuItem value={Quantisize.HALF_HOURS}>{t('reporting.quantisize.halfHours')}</MenuItem>
                                        <MenuItem value={Quantisize.HOURS}>{t('reporting.quantisize.hours')}</MenuItem>
                                    </Select>
                                    <FormHelperText>{t('reporting.quantisize.hint')}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box sx={{ padding: '0 1em' }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultValue={includeInfos}
                                                onChange={onChange}
                                                onBlur={(e: React.FocusEvent<HTMLButtonElement>) => {
                                                    clearIdle();
                                                    setIncludeInfos((e.target as HTMLInputElement).checked ? 'on' : 'off');
                                                }}
                                            />
                                        }
                                        label={t('reporting.include.infos')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultValue={includeColors}
                                                onChange={onChange}
                                                onBlur={(e: React.FocusEvent<HTMLButtonElement>) => {
                                                    clearIdle();
                                                    setIncludeColors((e.target as HTMLInputElement).checked ? 'on' : 'off');
                                                }}
                                            />
                                        }
                                        label={t('reporting.include.colors')}
                                    />
                                </FormGroup>
                            </Box>
                            <Box sx={{ flex: 1, margin: 'auto', textAlign: 'right' }}></Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </AppBar>
            <TableContainer
                component={Paper}
                sx={{
                    flex: 1,
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <Head setHeight={setHeaderHeight} includeColor={includeColors === 'on'} includeInfo={includeInfos === 'on'} />
                    <TableBody>
                        {entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <Row
                                key={row.key}
                                row={row}
                                quantisize={+quantisize}
                                includeInfo={includeInfos === 'on'}
                                includeColor={includeColors === 'on'}
                                minHeight={rowHeight}
                                setHeight={setRowHeight}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ height: bottomBarRef.current?.offsetHeight + 'px' }}></Box>
            <AppBar ref={bottomBarRef} position="static" color="inherit" sx={{ position: 'fixed', bottom: 0, padding: 0 }}>
                <Toolbar variant="dense">
                    <Box sx={{ marginRight: '1rem' }}>
                        <Tooltip arrow title={t('reporting.close')}>
                            <IconButton aria-label={t('reporting.close')} onClick={() => navigate('/')} edge="end">
                                <ClearIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {Object.keys(sync.pairings)
                        .filter((p) => sync.pairings[p].active && sync.pairings[p].sync === ConnectionMode.INVOICE)
                        .map((p) => (
                            <Box key={p} sx={{ marginRight: '1rem' }}>
                                <Tooltip arrow title={t('sync.add.invoice') + ' ' + sync.pairings[p].displayName}>
                                    <IconButton
                                        aria-label={t('sync.add.invoice') + ' ' + sync.pairings[p].displayName}
                                        onClick={() => generateInvoices(sync.pairings[p].displayName)}
                                        edge="end"
                                    >
                                        <ReceiptLongIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                    <Box sx={{ marginRight: '1rem' }}>
                        <Tooltip arrow title={t('reporting.csv')}>
                            <IconButton aria-label={t('reporting.csv')} onClick={() => downloadReport()} edge="end">
                                <DownloadIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {entries.length > rowsPerPage && (
                        <TablePagination
                            sx={{ flex: 1 }}
                            component="div"
                            count={entries.length}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[rowsPerPage]}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
