export function dayStart(time: number) {
    const d = new Date();
    d.setTime(time);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.getTime();
}

export function dayEnd(time: number) {
    const d = new Date();
    d.setTime(time);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    d.setMilliseconds(999);
    return d.getTime();
}

export function weekStart(time: number) {
    const d = new Date();
    d.setTime(time);
    d.setDate(d.getDate() - d.getDay() + 1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.getTime();
}

export function monthStart(time: number) {
    const d = new Date();
    d.setTime(time);
    d.setDate(1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.getTime();
}

export function yearStart(time: number) {
    const d = new Date();
    d.setTime(time);
    d.setMonth(0);
    d.setDate(1);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    return d.getTime();
}
