import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getJiraDuration, getQuantisized } from '../utils/timeline-utils';

import { Entry } from './entry';

export function Row(props: {
    row: Entry;
    quantisize: number;
    includeColor: boolean;
    includeInfo: boolean;
    minHeight: number;
    setHeight: (height: number) => void;
}) {
    const { t } = useTranslation();
    const { row, quantisize, includeColor, includeInfo } = props;

    const ref = useRef<HTMLTableRowElement>(null);
    useEffect(() => {
        if (props.minHeight < ref.current?.offsetHeight) {
            props.setHeight(ref.current?.offsetHeight);
        }
    }, [props, ref]);
    return (
        <TableRow ref={ref} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell style={{ maxWidth: '50vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {format(new Date(row.start), t('date.format'))}
            </TableCell>
            <TableCell align="right">{row.name}</TableCell>
            {includeColor && (
                <TableCell align="right" sx={{ backgroundColor: row.color }}>
                    {row.color}
                </TableCell>
            )}
            {includeInfo && <TableCell align="right">{row.info}</TableCell>}
            <TableCell align="right">{row.time ? getJiraDuration(24 * 7, 24, getQuantisized(quantisize, row.time)) : ''}</TableCell>
            <TableCell align="right">{row.sum ? getJiraDuration(24 * 7, 24, getQuantisized(quantisize, row.sum)) : ''}</TableCell>
        </TableRow>
    );
}
