import './Timeline.component.css';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, IconButton, Slider, ToggleButton, Tooltip, useTheme } from '@mui/material';
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getEvents, moveCursorToNext, moveCursorToPrevious } from '../utils/event-utils';
import { getCursorType, getDuration, getTicks, getLocalTimeStr, getLeft, getBackgroundShift, widthToTime } from '../utils/timeline-utils';

import { Event } from './event';
import { TimelineTick } from './timeline-tick';

type ComponentProps = {
    cursor: number;
    focus: number;
    zoom: number;
    setZoom: (zoom: number) => void;
    saveNow: number;
    onCursorChanged: (time: number, isNow: boolean) => void;
};

export function TimelineComponent({ cursor, focus, zoom, setZoom, saveNow, onCursorChanged }: ComponentProps) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const timelineRef = useRef<HTMLDivElement>(null);
    const [ticks, setTicks] = useState<TimelineTick[]>([]);
    const [width, setWidth] = useState(timelineRef.current?.offsetWidth || 0);
    const [backgroundShift, setBackgroundShift] = useState(0);
    const [timeTipOffset, setTimeTipOffset] = useState(0);
    const [timeTipText, setTimeTipText] = useState('');
    const [infos, setInfos] = useState<Event[]>([]);
    const [activities, setActivities] = useState<Event[]>([]);
    const [zoomSliderVisible, setZoomSliderVisible] = useState(false);

    const span = useMemo(() => Math.floor(widthToTime(zoom, width)), [zoom, width]);

    useEffect(() => {
        setTicks(getTicks(i18n.language, cursor, focus, zoom, saveNow, span));
    }, [cursor, focus, zoom, saveNow, span, setTicks, i18n]);

    useEffect(() => {
        const from = cursor - focus * span;
        const events = getEvents(from, from + span);
        setActivities(events.filter((e) => !!e.n && !!e.c));
        setInfos(events.filter((e) => !!e.i));
    }, [cursor, focus, zoom, saveNow, span, setActivities, setInfos]);

    const handleWindowSizeChange = useCallback(() => {
        setWidth(timelineRef.current?.offsetWidth);
    }, [setWidth]);

    useEffect(() => {
        setWidth(timelineRef.current?.offsetWidth);
    }, [setWidth, timelineRef]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [handleWindowSizeChange]);

    useEffect(() => {
        setBackgroundShift(getBackgroundShift(cursor, focus, zoom, span));
    }, [cursor, focus, zoom, span, setBackgroundShift]);

    const timeHover = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            setTimeTipOffset(event.clientX);
            setTimeTipText(getLocalTimeStr(i18n.language, saveNow, new Date(cursor - focus * span + widthToTime(zoom, event.clientX))));
        },
        [cursor, focus, zoom, saveNow, span, setTimeTipOffset, setTimeTipText, i18n.language]
    );
    const timeClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            const time = cursor - focus * span + widthToTime(zoom, event.clientX);
            onCursorChanged(time, time === saveNow);
            setTimeTipText('');
        },
        [cursor, focus, zoom, span, onCursorChanged, setTimeTipText, saveNow]
    );

    const moveToPrevious = useCallback((time: number) => moveCursorToPrevious(time, saveNow, onCursorChanged), [saveNow, onCursorChanged]);
    const moveToNext = useCallback((time: number) => moveCursorToNext(time, saveNow, onCursorChanged), [saveNow, onCursorChanged]);

    return (
        <div className="timeline" ref={timelineRef}>
            <div
                className={'time ' + theme.palette.mode}
                onMouseMove={timeHover}
                onMouseLeave={() => setTimeTipText('')}
                onClick={timeClick}
                style={{ backgroundPositionX: -backgroundShift + 'px' }}
            >
                {timeTipText && (
                    <div className="timetip" style={{ left: timeTipOffset + 'px' }}>
                        <span className="timetipText">{timeTipText}</span>
                    </div>
                )}
                <div className="tHours">
                    <div className="tSeparators">
                        {ticks.map((tick, index) =>
                            tick.left < width ? (
                                <div
                                    key={'tSep' + index}
                                    style={{ left: tick.left + 'px', top: tick.top + 'px', height: 71 - tick.top + 'px' }}
                                >
                                    {tick.top === 0 ? tick.label : ''}
                                </div>
                            ) : null
                        )}
                    </div>
                </div>
                <div className="tLine" unselectable="on">
                    {activities.map((activity, index, activities) => {
                        const left = getLeft(cursor, focus, zoom, width, activity);
                        const duration = getDuration(
                            saveNow,
                            zoom,
                            activity,
                            index + 1 < activities.length ? activities[index + 1] : undefined
                        );
                        let l = left;
                        let w = duration;
                        if (l < 0) {
                            w += l;
                            l = 0;
                        }
                        if (l + w > width) {
                            w = width - l;
                        }
                        return l < width && w > 0 ? (
                            <Tooltip arrow key={'act_' + activity.s} title={activity.n}>
                                <div
                                    className="evt"
                                    style={{
                                        left: l + 'px',
                                        width: w + 'px',
                                        backgroundColor: activity.c,
                                    }}
                                ></div>
                            </Tooltip>
                        ) : null;
                    })}
                    {infos.map((info) => {
                        const left = getLeft(cursor, focus, zoom, width, info);
                        return left >= 0 && left < width ? (
                            <Tooltip arrow key={'nfo_' + info.s} title={info.i}>
                                <div className="ti" style={{ left: left + 'px' }}></div>
                            </Tooltip>
                        ) : null;
                    })}
                    <Tooltip arrow key="tNow" title={t('time.cursor.' + getCursorType(cursor, saveNow))}>
                        <div className={'nowimg tNow ' + getCursorType(cursor, saveNow)} style={{ left: focus * width + 'px' }}></div>
                    </Tooltip>
                </div>
            </div>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 11,
                }}
            >
                <Tooltip arrow title={t('time.previous')}>
                    <IconButton
                        size="large"
                        aria-label={t('time.previous')}
                        aria-controls={'primary-search-account-menu-mobile'}
                        aria-haspopup="true"
                        onClick={() => moveToPrevious(cursor)}
                        color="primary"
                    >
                        <SkipPreviousIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip arrow title={t('time.next')}>
                    <IconButton
                        size="large"
                        aria-label={t('time.next')}
                        aria-controls={'primary-search-account-menu-mobile'}
                        aria-haspopup="true"
                        onClick={() => moveToNext(cursor)}
                        color="primary"
                    >
                        <SkipNextIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {zoomSliderVisible && (
                <Slider
                    aria-label="Volume"
                    min={10}
                    max={640}
                    value={zoom}
                    onChange={(event, value) => value !== zoom && setZoom(value as number)}
                    sx={{
                        position: 'absolute',
                        right: '4em',
                        top: '8px',
                        width: '30vw',
                        zIndex: 11,
                    }}
                />
            )}
            <Tooltip arrow title={t('timeline.zoom')}>
                <ToggleButton
                    value={zoomSliderVisible}
                    aria-label={t('timeline.zoom')}
                    onChange={() => setZoomSliderVisible(!zoomSliderVisible)}
                    color="primary"
                    sx={{
                        position: 'absolute',
                        right: '1em',
                        border: 0,
                        zIndex: 11,
                    }}
                >
                    <ZoomInIcon color="primary" />
                </ToggleButton>
            </Tooltip>
        </div>
    );
}
