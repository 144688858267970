import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function Head(props: { includeColor: boolean; includeInfo: boolean; setHeight: (height: number) => void }) {
    const { t } = useTranslation();

    const head = useRef<HTMLTableRowElement>(null);
    useEffect(() => props.setHeight(head.current?.offsetHeight), [props, head]);
    return (
        <TableHead>
            <TableRow ref={head}>
                <TableCell style={{ fontWeight: 700, width: '25%' }}>{t('reporting.date')}</TableCell>
                <TableCell style={{ fontWeight: 700, width: '25%' }} align="right">
                    {t('reporting.name')}
                </TableCell>
                {props.includeColor && (
                    <TableCell style={{ fontWeight: 700, width: '25%' }} align="right">
                        {t('reporting.color')}
                    </TableCell>
                )}
                {props.includeInfo && (
                    <TableCell style={{ fontWeight: 700, width: '25%' }} align="right">
                        {t('reporting.info')}
                    </TableCell>
                )}
                <TableCell style={{ fontWeight: 700, width: '25%' }} align="right">
                    {t('reporting.time')}
                </TableCell>
                <TableCell style={{ fontWeight: 700, width: '25%' }} align="right">
                    {t('reporting.sum')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
