import { TFunction } from 'i18next';

import { Activity } from '../tracking/activity';
import { Cell } from '../tracking/cell';

export function initialLayout(t?: TFunction) {
    return (
        '{"v":"2","format":"time.emphasize","r":{"l":"v","s":[' +
        '{"l":"h","s":[{"n":"' +
        t('work') +
        '","c":"#4aff00"},{"n":"' +
        t('sleep') +
        '","c":"#ffaa11"}]},' +
        '{"l":"v","s":[{"n":"' +
        t('eat') +
        '","c":"#00afff"},{"n":"' +
        t('leasure') +
        '","c":"#308a11"}]}]}}'
    );
}

export function getAllActivities(layout: string): Activity[] {
    const all: Activity[] = [];
    const root = Cell.fromJson(layout);
    const recurse = (cell: Cell) => {
        if (cell.s) {
            recurse(cell.s[0]);
            recurse(cell.s[1]);
        } else {
            all.push({ n: cell.n, c: cell.c } as Activity);
        }
    };
    recurse(root);
    return all;
}
