export enum Quantisize {
    NONE = 1,
    SECONDS = 1000,
    MINUTES = 60000,
    FIVE_MINUTES = 300000,
    TEN_MINUTES = 600000,
    FIFTEEN_MINUTES = 900000,
    HALF_HOURS = 1800000,
    HOURS = 3600000,
}
