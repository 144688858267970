import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { get, put } from './storage-impl';

export function useConfig<S>(key: string, defaultValue: S, persistent = true): [S, Dispatch<SetStateAction<S>>] {
    const initValue = useMemo(() => {
        const v = persistent ? get(key) : undefined;
        if (v) {
            return typeof defaultValue === 'string' ? v : JSON.parse(v);
        }
        return defaultValue;
    }, [persistent, defaultValue, key]);
    const [value, setValue] = useState(initValue);

    const setter = useCallback(
        (newValue: SetStateAction<S>) => {
            if (JSON.stringify(newValue) !== JSON.stringify(value)) {
                if (persistent) {
                    put(key, typeof defaultValue === 'string' ? (newValue as string) : JSON.stringify(newValue));
                }
                setValue(newValue);
            }
        },
        [persistent, value, key, defaultValue, setValue]
    );

    return [value, setter];
}
