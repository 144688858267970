import { dayStart, monthStart, weekStart, yearStart } from '../utils/date-utils';

export enum Group {
    OVERALL = 'overall',
    PER_DAY = 'day',
    PER_WEEK = 'week',
    PER_MONTH = 'month',
    PER_YEAR = 'year',
}

export function groupStart(group: Group, time: number, from: number): number {
    switch (group) {
        case Group.OVERALL:
            return from;
        case Group.PER_DAY:
            return dayStart(time);
        case Group.PER_WEEK:
            return weekStart(time);
        case Group.PER_MONTH:
            return monthStart(time);
        case Group.PER_YEAR:
            return yearStart(time);
        default:
            throw new Error('unknown group value ' + group);
    }
}
