import { KEY_USER_ID } from './locale.storage.keys';

export function getKeys() {
    return Object.keys(localStorage);
}

export function put(key: string, value: string) {
    localStorage.setItem(key, value);
}

export function deleteKey(key: string) {
    localStorage.removeItem(key);
}

export function clear() {
    localStorage.clear();
}

export function get(key: string) {
    return localStorage.getItem(key);
}

export function ui(): string {
    let ui = get(KEY_USER_ID);
    if (!ui) {
        ui = (+('' + Math.random()).replace(/[,.]/g, '')).toString(36);
        put(KEY_USER_ID, ui);
    }
    return ui;
}
