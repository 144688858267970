export function csvTable(rows: unknown[], headers?: string[]): Blob {
    if (!rows || !rows.length) {
        return;
    }
    const separator = ',';

    const keys: string[] = Object.keys(rows[0]);

    let columHearders: string[];

    if (headers) {
        columHearders = headers;
    } else {
        columHearders = keys;
    }

    const csvContent =
        columHearders.join(separator) +
        '\n' +
        rows
            .map((row) => {
                return keys
                    .map((k) => {
                        let cell = row[k] === null || row[k] === undefined ? '' : row[k];

                        cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

                        if (cell.search(/("|,|\n)/g) >= 0) {
                            cell = `"${cell}"`;
                        }
                        return cell;
                    })
                    .join(separator);
            })
            .join('\n');

    return new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
}
