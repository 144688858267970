import { endOfDay, isSameDay, startOfDay, addDays } from 'date-fns';

import { Entry } from '../reporting/entry';
import { Group, groupStart } from '../reporting/group';
import { Event } from '../timeline/event';
import { groupBy } from '../utils/group-by';

import { getEvents } from './event-utils';

export function getEntries(from: Date, to: Date, saveNow: number, group: Group, includeInfos: boolean, includeColors: boolean) {
    const bareEvents = getEvents(from.getTime(), endOfDay(to).getTime());
    const events: Event[] = bareEvents.flatMap((e, index, all) => {
        const ec = { ...e } as Event;
        if (ec.s < from.getTime()) {
            ec.s = from.getTime();
        }
        if (!!ec.n && !ec.e) {
            let f: number | undefined = undefined;
            let i = index + 1;
            while (i < all.length && !f) {
                if (!!all[i].n) {
                    f = all[i].s;
                }
                i++;
            }
            if (!f) {
                f = saveNow;
            }
            ec.e = f;
        }
        if (!ec.e || isSameDay(ec.s, ec.e)) {
            return [ec];
        }
        const p = [];
        for (let d = ec.s; d < ec.e && d < to.getTime(); d = startOfDay(addDays(d, 1)).getTime()) {
            p.push({ ...ec, s: d, e: Math.min(ec.e, endOfDay(d).getTime()) } as Event);
        }
        return p;
    });
    const records = groupBy(events, (e) => groupStart(group, e.s, from.getTime()));
    let n = 0;
    let lastEntry;
    return Object.keys(records).flatMap((k) => {
        return records[k].flatMap((e, i, all) => {
            let time = !!e.e ? e.e - e.s : 0;
            let sum = undefined;

            if (i === all.length - 1) {
                sum = 0;
                all.forEach((a) => {
                    sum += !!a.e ? a.e - a.s : 0;
                });
            }

            if (all.filter((o) => o.n === e.n).length > 1) {
                if (i === all.findIndex((o) => o.n === e.n)) {
                    time = 0;
                    all.filter((o) => o.n === e.n)
                        .map((o) => (!!o.e ? o.e - o.s : 0))
                        .forEach((t) => (time += t));
                } else {
                    if (lastEntry && !!sum) {
                        lastEntry.sum = sum;
                    }
                    return [];
                }
            }

            let infos = '';
            if (includeInfos) {
                if (!!e.n) {
                    all.filter((a) => a.n === e.n).forEach((a) => {
                        all.filter((o) => !!o.i && o.s >= a.s && o.s < a.e).forEach(
                            (o) => (infos += (infos.length > 0 ? ' | ' : '') + o.i)
                        );
                    });
                } else {
                    // all infos outside of activities
                    let outsides = all.filter((o) => !!o.i);
                    all.filter((a) => !!a.n).forEach((a) => {
                        outsides = outsides.filter((o) => o.s < a.s || o.s >= a.e);
                    });
                    outsides.forEach((o) => (infos += (infos.length > 0 ? ' | ' : '') + o.i));
                }
            }

            const entry = {
                key: n++,
                start: groupStart(group, e.s, from.getTime()),
                name: e.n,
                time,
                sum,
            } as Entry;

            if (includeInfos) {
                entry.info = infos;
            }
            if (includeColors) {
                entry.color = e.c;
            }
            lastEntry = entry;
            return [entry];
        });
    });
}
