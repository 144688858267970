import { Box } from '@mui/material';
import React, { KeyboardEvent, MouseEvent, SyntheticEvent, useMemo, useState } from 'react';

import { domain } from '../utils/domain';
import { getFontColor } from '../utils/font-color';
import { getSplitSection } from '../utils/split-section';
import { ui } from '../utils/storage-impl';

import { Cell } from './cell';
import { CellMode } from './cell-mode';
import { CellContext } from './Tracking.component';

type ComponentProps = {
    cell: Cell;
};

export function CellComponent({ cell }: ComponentProps) {
    const context = React.useContext(CellContext);
    const [hoverClass, setHoverClass] = useState('');
    const componentCell = useMemo(() => {
        return cell;
    }, [cell]);

    const onHover = (inside: boolean, event: MouseEvent<HTMLElement>) => {
        if (context?.spectatorMode === true) {
            return;
        }
        if (!inside) {
            setHoverClass('');
        } else if (context?.mode === CellMode.SPLIT) {
            const section = getSplitSection(event);
            setHoverClass(section);
        } else if (context?.mode === CellMode.REMOVE) {
            setHoverClass('remove');
        } else {
            setHoverClass('');
        }
    };

    if (componentCell.l !== 'v' && componentCell.l !== 'h') {
        // field
        if (componentCell.n === undefined) {
            throw new Error('componentCell name is undefined');
        }
        const backgroundColor = componentCell.c;
        const fontSize = Math.floor(500 / (Math.max(componentCell.n.length, 3) + 10));
        const color = getFontColor(componentCell.c);

        const updateName = (value: string, key: string) => {
            if (context?.spectatorMode === true) {
                return;
            }
            if (value !== undefined && (componentCell.n !== value || key === 'Enter' || key === 'Escape')) {
                componentCell.n = value.replaceAll('\t', ' ');
                context?.onNameChanged(componentCell, key);
            }
            if (value !== undefined && key === 'Enter') {
                context?.onClick(componentCell, {} as MouseEvent<HTMLElement>);
            }
        };

        const qrCodeLink =
            domain +
            '/link/?s=' +
            encodeURIComponent(ui()) +
            '&e=' +
            encodeURIComponent(componentCell.n).replaceAll('%2F', '%252F') +
            '&c=' +
            encodeURIComponent(componentCell.c.substring(1));
        const qrCodeUrl =
            domain +
            '/connect/' +
            encodeURIComponent(ui()) +
            '/push/' +
            encodeURIComponent(componentCell.n).replaceAll('%2F', '%252F') +
            '/' +
            encodeURIComponent(componentCell.c.substring(1)) +
            '/';

        return (
            <Box
                key="field"
                className={'field ' + hoverClass}
                title={componentCell.n}
                sx={{
                    fontSize: { xs: fontSize / 2, md: fontSize },
                    backgroundColor,
                    color,
                }}
                onClick={(event) => {
                    if (context?.spectatorMode === true) {
                        return;
                    }
                    context?.onClick(componentCell, event);
                }}
                onMouseMove={(event) => onHover(true, event)}
                onMouseOver={(event) => onHover(true, event)}
                onMouseOut={(event) => onHover(false, event)}
            >
                <span className="mid"></span>
                {(context?.spectatorMode === true || context?.mode !== CellMode.RENAME) && <span className="name">{componentCell.n}</span>}
                {context?.spectatorMode === false && context?.mode === CellMode.RENAME && (
                    <input
                        className="edit-name"
                        autoFocus={cell === context?.selected}
                        style={{ color }}
                        defaultValue={componentCell.n}
                        onKeyUp={(event: KeyboardEvent) => updateName((event.target as unknown)['value'], event.key)}
                        onBlur={(event: SyntheticEvent) => updateName((event.target as unknown)['value'], '')}
                        onClick={(event: SyntheticEvent) => event.stopPropagation()}
                    />
                )}
                {context?.spectatorMode === false && context?.mode === CellMode.QR && (
                    <a target="_blank" href={qrCodeUrl}>
                        <img alt="qrCode" className="qr" src={qrCodeLink} />
                    </a>
                )}
                {context?.spectatorMode === false && context?.mode !== CellMode.QR && (
                    <img alt="qrCode" className="print-only qr" src={qrCodeLink} />
                )}
            </Box>
        );
    }

    // split
    const v = componentCell.l === 'v';
    if (!componentCell.s) {
        throw new Error('split subcomponentCells undefined');
    }
    const height = v ? 100 - Math.round((10000 * componentCell.s[1]._containedRows) / componentCell._containedRows) / 100 + '%' : undefined;
    const width = v ? undefined : 100 - Math.round((10000 * componentCell.s[1]._containedCols) / componentCell._containedCols) / 100 + '%';

    const left = v ? undefined : 100 - Math.round((10000 * componentCell.s[1]._containedCols) / componentCell._containedCols) / 100 + '%';
    const top = v ? 100 - Math.round((10000 * componentCell.s[1]._containedRows) / componentCell._containedRows) / 100 + '%' : undefined;
    const height2 =
        componentCell.l === 'v'
            ? Math.round((10000 * componentCell.s[1]._containedRows) / componentCell._containedRows) / 100 + '%'
            : undefined;
    const width2 = v ? undefined : Math.round((10000 * componentCell.s[1]._containedCols) / componentCell._containedCols) / 100 + '%';

    return (
        <>
            <div key="s0" className={componentCell.l + '-s0'} style={{ height, width }}>
                <CellComponent cell={componentCell.s[0]} />
            </div>
            <div key="s1" className={componentCell.l + '-s1'} style={{ left, top, height: height2, width: width2 }}>
                <CellComponent cell={componentCell.s[1]} />
            </div>
        </>
    );
}
