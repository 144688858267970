import AddIcon from '@mui/icons-material/Add';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PreviewIcon from '@mui/icons-material/Preview';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StorageIcon from '@mui/icons-material/Storage';
import { Avatar, Button, Divider, IconButton, InputBase, ListItem, ListItemAvatar, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { CellMode } from '../tracking/cell-mode';
import { clone } from '../utils/clone';
import { domain } from '../utils/domain';
import { getAllActivities, initialLayout } from '../utils/layout-utils';
import { KEY_LAYOUT } from '../utils/locale.storage.keys';
import { ui } from '../utils/storage-impl';
import { useConfig } from '../utils/use-config';

import { ConnectionMode } from './connection-mode';
import { correctDisplayName, generatePairingKey } from './connection-utils';
import { Pairing } from './pairing';
import { Sync } from './sync';

type ComponentProps = {
    open: boolean;
    onClose: () => void;
    sync: Sync;
    setSync: (sync: Sync) => void;
    setMode: (mode: CellMode) => void;
};

export function ConnectionsDialog({ open, onClose, sync, setSync, setMode }: ComponentProps) {
    const { t } = useTranslation();

    const [anchorAddEl, setAnchorAddEl] = React.useState<null | HTMLElement>(null);
    const [layout] = useConfig<string>(KEY_LAYOUT, initialLayout(t));
    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAddEl(event.currentTarget);
    };
    const handleAddClose = () => {
        setAnchorAddEl(null);
    };
    const openAdd = Boolean(anchorAddEl);
    const addPairing = React.useCallback(
        (syncMode: ConnectionMode, displayName?: string) => {
            const newSync = clone(sync);
            const pairingKey = generatePairingKey(syncMode);

            newSync.pairings[pairingKey] = {
                active: true,
                sync: syncMode,
                channel: ui(),
                pairing: pairingKey,
                displayName: correctDisplayName(sync, pairingKey, syncMode, displayName),
            } as Pairing;
            setSync(newSync);
            if (newSync.pairings[pairingKey].active && newSync.pairings[pairingKey].sync === ConnectionMode.QRCODE_PUSH) {
                setMode(CellMode.QR);
            } else {
                setMode(CellMode.JUMP);
            }
        },
        [sync, setSync, setMode]
    );
    const [openTooltip, setOpenTooltip] = React.useState('');

    const removePushPairing = React.useCallback(
        (pairingKey: string) => {
            const newSync = clone(sync);
            delete newSync.pairings[pairingKey];
            setSync(newSync);
        },
        [sync, setSync]
    );

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle sx={{ m: 0, mr: 6, p: 2 }}>
                {t('sync.dialog.title')}{' '}
                <IconButton
                    aria-label="close"
                    data-testid="connections.close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                data-testid="connections.add"
                onClick={handleAddClick}
                sx={{ margin: '0 0 1em auto' }}
            >
                {t('sync.add')}
            </Button>
            <Menu
                id="main-menu"
                aria-labelledby="main-button"
                anchorEl={anchorAddEl}
                open={openAdd}
                onClose={handleAddClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {sync.pairings['_connect'] === undefined && (
                    <MenuItem
                        onClick={() => {
                            addPairing(ConnectionMode.QRCODE_PUSH);
                            handleAddClose();
                        }}
                    >
                        <ListItemIcon>
                            <AddAlertIcon />
                        </ListItemIcon>
                        {t('sync.add.push')}
                    </MenuItem>
                )}
                <MenuItem
                    data-testid="connections.pairing"
                    onClick={() => {
                        addPairing(ConnectionMode.SAME_USER);
                        handleAddClose();
                    }}
                >
                    <ListItemIcon>
                        <PhonelinkIcon />
                    </ListItemIcon>
                    {t('sync.add.device')}
                </MenuItem>
                {sync.pairings['_spectator'] === undefined && (
                    <MenuItem
                        onClick={() => {
                            addPairing(ConnectionMode.SPECTATOR);
                            handleAddClose();
                        }}
                    >
                        <ListItemIcon>
                            <PreviewIcon />
                        </ListItemIcon>
                        {t('sync.add.spectator')}
                    </MenuItem>
                )}
                {sync.pairings['_peep'] === undefined && (
                    <MenuItem
                        onClick={() => {
                            addPairing(ConnectionMode.PEEP);
                            handleAddClose();
                        }}
                    >
                        <ListItemIcon>
                            <LoyaltyIcon />
                        </ListItemIcon>
                        {t('sync.add.peep')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        addPairing(ConnectionMode.STORAGE, 'host/path');
                        handleAddClose();
                    }}
                >
                    <ListItemIcon>
                        <StorageIcon />
                    </ListItemIcon>
                    {t('sync.add.storage')}
                </MenuItem>
                {sync.pairings['_invoice'] === undefined && (
                    <MenuItem
                        onClick={() => {
                            addPairing(ConnectionMode.INVOICE, 'localhost:10801');
                            handleAddClose();
                        }}
                    >
                        <ListItemIcon>
                            <ReceiptLongIcon />
                        </ListItemIcon>
                        {t('sync.add.invoice')}
                    </MenuItem>
                )}
            </Menu>
            {Object.keys(sync.pairings).length ? <Divider /> : undefined}
            <List sx={{ pt: 0 }}>
                {Object.keys(sync.pairings).map((k) => (
                    <ListItem key={k}>
                        <ListItemAvatar>
                            <Avatar
                                title={
                                    sync.pairings[k].sync === ConnectionMode.QRCODE_PUSH
                                        ? t('sync.add.push')
                                        : sync.pairings[k].sync === ConnectionMode.SAME_USER
                                          ? t('sync.add.device')
                                          : sync.pairings[k].sync === ConnectionMode.SPECTATOR
                                            ? t('sync.add.spectator')
                                            : sync.pairings[k].sync === ConnectionMode.PEEP
                                              ? t('sync.add.peep')
                                              : sync.pairings[k].sync === ConnectionMode.STORAGE
                                                ? t('sync.add.storage')
                                                : sync.pairings[k].sync === ConnectionMode.INVOICE
                                                  ? t('sync.add.invoice')
                                                  : ''
                                }
                            >
                                {sync.pairings[k].sync === ConnectionMode.QRCODE_PUSH ? (
                                    <AddAlertIcon />
                                ) : sync.pairings[k].sync === ConnectionMode.SAME_USER ? (
                                    <PhonelinkIcon />
                                ) : sync.pairings[k].sync === ConnectionMode.SPECTATOR ? (
                                    <PreviewIcon />
                                ) : sync.pairings[k].sync === ConnectionMode.PEEP ? (
                                    <LoyaltyIcon />
                                ) : sync.pairings[k].sync === ConnectionMode.STORAGE ? (
                                    <StorageIcon />
                                ) : sync.pairings[k].sync === ConnectionMode.INVOICE ? (
                                    <ReceiptLongIcon />
                                ) : undefined}
                            </Avatar>
                        </ListItemAvatar>
                        <InputBase
                            defaultValue={sync.pairings[k].displayName}
                            sx={{ flex: 1 }}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                const newSync = clone(sync);
                                newSync.pairings[k].displayName = event.target.value;
                                setSync(newSync);
                            }}
                        />

                        {sync.pairings[k].sync === ConnectionMode.QRCODE_PUSH && sync.pairings[k].active ? (
                            <Tooltip arrow placement={openTooltip === k ? 'top' : 'bottom'} title={t('sync.print.list_qrcode_events')}>
                                <IconButton
                                    aria-label={t('sync.print.list_qrcode_events')}
                                    onClick={() => {
                                        let html = '';
                                        html += '<head>';
                                        html += '<meta charset="utf-8">';
                                        html += '<title>Title</title>';
                                        html += '</head>';

                                        html += '<body style="background-color: white;">';
                                        html += '<div">';
                                        const activities = getAllActivities(layout);
                                        for (const activity of activities) {
                                            const url =
                                                domain +
                                                '/link/?s=' +
                                                encodeURIComponent(sync.pairings[k].channel) +
                                                '&e=' +
                                                encodeURIComponent(activity.n) +
                                                '&c=' +
                                                encodeURIComponent(activity.c.substring(1));
                                            html +=
                                                '<figure style="display: inline-block;border-bottom: 1rem solid #fff;"><img alt="qrCode ' +
                                                url +
                                                '" style="border-right: 1rem solid #fff;" src="' +
                                                url +
                                                '" /><figcaption>' +
                                                activity.n +
                                                '</figcaption></figure>';
                                        }
                                        html += '</div>';
                                        html += '</body>';
                                        html += '</html>';

                                        const newWin = window.open();
                                        if (newWin) {
                                            newWin.document.write(html);
                                            newWin.document.close();
                                            newWin.print();
                                            newWin.close();
                                        }
                                    }}
                                    edge="end"
                                >
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                        ) : undefined}
                        {[ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR, ConnectionMode.STORAGE].includes(sync.pairings[k].sync) &&
                        sync.pairings[k].active ? (
                            <Tooltip
                                arrow
                                placement={openTooltip === k ? 'top' : 'bottom'}
                                title={openTooltip === k ? t('sync.link.copied') : t('sync.link.copy')}
                            >
                                <IconButton
                                    aria-label={openTooltip === k ? t('sync.link.copied') : t('sync.link.copy')}
                                    data-testid="clipboard.copy"
                                    data-url={
                                        sync.pairings[k].sync === ConnectionMode.SPECTATOR
                                            ? `${domain}/view/${encodeURIComponent(sync.pairings[k].channel)}/`
                                            : `${domain}/?m=` +
                                              (sync.pairings[k].sync === ConnectionMode.SAME_USER
                                                  ? 'a'
                                                  : sync.pairings[k].sync === ConnectionMode.STORAGE
                                                    ? 's'
                                                    : '-') +
                                              '&c=' +
                                              encodeURIComponent(sync.pairings[k].channel) +
                                              (sync.pairings[k].sync !== ConnectionMode.STORAGE ? '&p=' + encodeURIComponent(k) : '') +
                                              '&d=' +
                                              encodeURIComponent(sync.pairings[k].displayName)
                                    }
                                    onClick={(event) => {
                                        navigator.clipboard.writeText(event.currentTarget.getAttribute('data-url'));
                                        setOpenTooltip(k);
                                        setTimeout(() => setOpenTooltip(''), 3000);
                                    }}
                                    edge="end"
                                >
                                    <IntegrationInstructionsIcon />
                                </IconButton>
                            </Tooltip>
                        ) : undefined}
                        {[ConnectionMode.PEEP].includes(sync.pairings[k].sync) && sync.pairings[k].active ? (
                            <Tooltip
                                arrow
                                placement={openTooltip === k ? 'top' : 'bottom'}
                                title={openTooltip === k ? t('sync.embed.copied') : t('sync.embed.copy')}
                            >
                                <IconButton
                                    aria-label={openTooltip === k ? t('sync.embed.copied') : t('sync.embed.copy')}
                                    data-testid="clipboard.copy"
                                    data-url={
                                        sync.pairings[k].sync === ConnectionMode.PEEP
                                            ? `<a data-channel="${encodeURIComponent(sync.pairings[k].channel)}" href="https://www.emphasize.de/" title="free time-reporting tool" class="time2-badge"> ` +
                                              t('badge') +
                                              ` <span>time-reporting tool</span></a>` +
                                              '\n' +
                                              `<script>const s=document.createElement("style");s.innerText='.time2-badge{border:1px solid grey!important;border-radius:4px!important;background:linear-gradient(0deg,#000,#555)!important;color:#fff!important;text-decoration:none!important;font-family:sans-serif!important;padding:0 0 0 4px!important;white-space:nowrap!important}.time2-badge:before{content:""!important;background-image:url(https://www.emphasize.de/favicon.ico)!important;width:1rem!important;height:1rem!important;display:inline-block!important;background-size:cover!important}.time2-badge span{background-color:#0a0;padding:0 4px!important;border-radius:0 3px 3px 0!important}',document.head.appendChild(s),setTimeout((()=>{const e=document.getElementsByClassName("time2-badge");for(let t=0;t<e.length;t++){const n=e[t].getAttribute("data-channel"),o=e[t].childNodes[e[t].childNodes.length-1];new EventSource("${domain}/event/?u=_badge&topic="+n).onmessage=e=>{const t=JSON.parse(e.data);if(Array.isArray(t))for(var n=0;n<t.length;n++){var a=t[n];a.s&&a.n&&a.c&&a.e?(o.innerText="off",o.style.color="#000",o.style.backgroundColor="#fff"):a.s&&a.n&&a.c&&(o.innerText=a.n,o.style.color=(parseInt(a.c.charAt(1),16)+parseInt(a.c.charAt(7===a.c.length?3:2),16)+parseInt(a.c.charAt(7===a.c.length?5:3),16))/3<8?"#fff":"#000",o.style.backgroundColor=a.c)}},fetch("${domain}/event/?p=_peep&u=_badge&i="+n,{body:'{"peep": "update"}',method:"POST",keepalive:!0}).catch((e=>{o.innerText="could not connect",o.style.backgroundColor="#a00"}))}}),500);</script>`
                                            : '-'
                                    }
                                    onClick={(event) => {
                                        navigator.clipboard.writeText(event.currentTarget.getAttribute('data-url'));
                                        setOpenTooltip(k);
                                        setTimeout(() => setOpenTooltip(''), 3000);
                                    }}
                                    edge="end"
                                >
                                    <IntegrationInstructionsIcon />
                                </IconButton>
                            </Tooltip>
                        ) : undefined}
                        <Switch
                            edge="end"
                            onChange={() => {
                                const newSync = clone(sync);
                                newSync.pairings[k].active = !newSync.pairings[k].active;
                                setSync(newSync);
                            }}
                            checked={sync.pairings[k].active}
                            inputProps={{
                                'aria-labelledby': 'switch-list-label-wifi',
                            }}
                        />
                        <IconButton aria-label="close" onClick={() => removePushPairing(k)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
