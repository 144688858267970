import { format, parseISO } from 'date-fns';

import { Event } from '../timeline/event';

import { dayEnd, dayStart } from './date-utils';
import { deleteKey, get, getKeys, put } from './storage-impl';

export const SEPERATOR_EVENTS = `\n`;
const SEPERATOR_PARTS = `\t`;

const LS_EVENT_PREFIX = 'events_';

export function eventFromString(s: string): Event {
    const parts = s.split(SEPERATOR_PARTS);
    const e = { s: +parts[0] } as Event;
    switch (parts.length) {
        case 2: // info
            e.i = parts[1];
            break;
        case 3: // activity without end
            e.n = parts[1];
            e.c = parts[2];
            break;
        case 4: // activity with end
            e.n = parts[1];
            e.c = parts[2];
            e.e = +parts[3];
            break;
        default:
            throw new Error();
    }
    return e;
}

export function eventToString(e: Event) {
    let s = '' + e.s;
    if (!!e.i) {
        s += SEPERATOR_PARTS + e.i;
    } else {
        s += SEPERATOR_PARTS + e.n + SEPERATOR_PARTS + e.c;
        if (e.e) {
            s += SEPERATOR_PARTS + e.e;
        }
    }
    return s;
}

function compareEvents(a: Event, b: Event) {
    if (a.s > b.s) {
        return 1;
    }
    if (a.s < b.s) {
        return -1;
    }
    if (!!a.i && !b.i) {
        return 1;
    }
    if (!a.i && !!b.i) {
        return -1;
    }
    return (a.n + a.i).localeCompare(b.n + b.i);
}

export function addEventToStrings(s: string, e: Event): Event[] {
    const events = !!s ? s.split(SEPERATOR_EVENTS).map(eventFromString) : [];
    const replace = events.findIndex((o) => o.s === e.s && (o.i !== undefined) === (e.i !== undefined));
    if (replace !== -1) {
        events.splice(replace, 1);
    }
    if (!e.i) {
        const cutoff = events.findIndex((o) => o.s < e.s && o.e > e.s && o.i === undefined);
        if (cutoff !== -1) {
            if (!e.e) {
                e.e = events[cutoff].e;
            }
            events[cutoff].e = e.s;
        } else if (!e.e) {
            const after = events.findIndex((o) => o.s > e.s && o.i === undefined);
            if (after !== -1) {
                e.e = events[after].s;
            }
        }
    }
    events.push(e);
    events.sort(compareEvents);
    return events.filter((e) => e.i !== ''); // filter out empty infos
}

export function getNextEventDayEnd(from: number): number {
    const available = getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .sort();

    const fromDay = dayStart(from);
    const i = available.findIndex((k) => parseISO(k.substring(LS_EVENT_PREFIX.length)).getTime() > fromDay);
    if (i === -1) {
        return dayEnd(from);
    }
    return dayEnd(parseISO(available[i].substring(LS_EVENT_PREFIX.length)).getTime());
}

function getEventDayKeys(): string[] {
    return getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .sort();
}

function getDayEvents(key: string) {
    const es = get(key);
    return !!es ? es.split(SEPERATOR_EVENTS).map(eventFromString) : [];
}

export function getEvents(from: number, to: number) {
    if (from > to) {
        throw new Error('from after to');
    }
    // will return the activity before from, too
    const available = getEventDayKeys();

    const fromDay = dayStart(from);
    let f = Math.max(0, available.findIndex((k) => parseISO(k.substring(LS_EVENT_PREFIX.length)).getTime() >= fromDay) - 1);
    let activityFound = false;
    while (f > 0 && !activityFound) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.filter((e) => !!e.n).length) {
            activityFound = true;
        } else {
            f--;
        }
    }
    const results: Event[] = [];
    while (f < available.length) {
        const dayEvents = getDayEvents(available[f]);
        if (dayEvents.length > 0) {
            results.push(...dayEvents);
            if (dayEvents[dayEvents.length - 1].s > to) {
                break;
            }
        }
        f++;
    }
    let fromActivity = 0;
    let toEvent = -1;
    for (let t = 0; t < results.length; t++) {
        if (!!results[t].n && results[t].s < from) {
            fromActivity = t;
        }
        if (results[t].s <= to) {
            toEvent = t;
        } else {
            break;
        }
    }
    if (toEvent === -1) {
        return [];
    }
    return results.slice(fromActivity, toEvent + 1);
}

export function addEvent(event: Event) {
    const date = format(new Date(event.s), 'yyyy-MM-dd');
    const s = get(LS_EVENT_PREFIX + date);
    const dayEvents = addEventToStrings(s, event);
    put(LS_EVENT_PREFIX + date, dayEvents.map(eventToString).join(SEPERATOR_EVENTS));
}

const DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT = '__#_#QuoTe#_#__';
const COMMA_IN_QUOTES_TEMP_REPLACEMENT = '__#_#CoMma#_#__';

export function validateEventsImport(s: string) {
    const rows = s.split('\n');
    if (rows.length === 0) {
        throw new Error('invalid format');
    }
    if (rows[0] !== 'unixtimestamp,event,color,end') {
        throw new Error('unexpected column format >' + s);
    }
    const p = [0];
    for (let l = 1; l < rows.length; l++) {
        const c = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        if (c.length < 2 || c.length > 4) {
            throw new Error('unexpected number of columns ' + c.length);
        }
        if ('' + Math.floor(Math.abs(+c[0])) !== c[0] || p[0] > +c[0]) {
            throw new Error('invalid unixtimestamp ' + c[0]);
        }
        if (c[2] && !c[2].match(/#([0-9a-f]{3}){1,2}/)) {
            throw new Error('invalid color ' + c[2]);
        }
        if (c[3] && ('' + Math.floor(Math.abs(+c[3])) !== c[3] || +c[0] >= +c[3])) {
            throw new Error('invalid end ' + c[3]);
        }

        p[0] = +c[0];
    }
}

export function clearAllEvents() {
    getKeys()
        .filter((k) => k.startsWith(LS_EVENT_PREFIX))
        .forEach((k) => deleteKey(k));
}

export function importEvents(s: string, onEvent: (event: Event) => void) {
    const rows = s.split('\n');
    for (let l = 1; l < rows.length; l++) {
        const line = rows[l]
            .replaceAll(/""/g, DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT)
            .replaceAll(/"(.*?)"/g, (h) => h.replaceAll(',', COMMA_IN_QUOTES_TEMP_REPLACEMENT))
            .replaceAll(/"(.*?)"/g, '$1')
            .replace(/,+$/g, '');
        const c = line.split(',');
        if (l === rows.length - 1 && c.length === 1 && c[0] === '') {
            continue;
        }
        const e = { s: +c[0] } as Event;
        if (c.length === 2) {
            e.i = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
        } else if (c.length === 3) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
        } else if (c.length === 4) {
            e.n = c[1].replaceAll(COMMA_IN_QUOTES_TEMP_REPLACEMENT, ',').replaceAll(DOUBLEQUOTE_IN_QUOTES_TEMP_REPLACEMENT, '"');
            e.c = c[2];
            e.e = +c[3];
        } else {
            throw new Error('unexpected amount of columns ' + c.length);
        }
        onEvent(e);
    }
}

export const moveCursorToPrevious = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const events = getEvents(time, time);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const previous = times.filter((t) => t < time);
    if (previous.length) {
        const to = previous[previous.length - 1];
        onCursorChanged(to, to === saveNow);
    }
};

export const moveCursorToNext = (time: number, saveNow: number, onCursorChanged: (time: number, isNow: boolean) => void) => {
    const to = getNextEventDayEnd(time);
    const events = getEvents(time, to);
    const times = events.flatMap((e) => (e.i ? [e.s] : [e.s, e.e]));
    times.push(saveNow);
    times.sort();
    const next = times.filter((t) => t > time);
    if (next.length) {
        const to = next[0];
        onCursorChanged(to, to === saveNow);
    }
};
