import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getChannels, postChannel } from './connect/channel-utils';
import { ConnectionMode } from './connect/connection-mode';
import { Pairing } from './connect/pairing';
import { Event } from './timeline/event';
import { CellMode } from './tracking/cell-mode';
import { TrackingComponent } from './tracking/Tracking.component';
import { initialLayout } from './utils/layout-utils';

const spectatorUpdate = [false];
const ui = (+('' + Math.random()).replace(/[,.]/g, '')).toString(36);

export function Spectator() {
    const { t } = useTranslation();
    const messageBar = useSnackbar();
    const params = useParams();
    const channel = params.channel;

    const sync = useMemo(
        () => ({
            pairings: {
                _spectator: {
                    active: true,
                    sync: ConnectionMode.SPECTATOR,
                    channel,
                    pairing: '_spectator',
                    displayName: 'Spectator',
                } as Pairing,
            },
            version: 0,
        }),
        [channel]
    );

    const [cursorEvent, setCursorEvent] = useState<Event>();
    const [layout, setLayout] = useState<string>(initialLayout(t));

    const showEvent = useCallback(
        (event: Event) => {
            if (event.n && !event.e) {
                setCursorEvent(event);
                document.title = event.n + ' - Time2Emphasize';
            } else if (event.i) {
                messageBar.enqueueSnackbar(format(event.s, 'HH:mm') + ' ' + event.i, { variant: 'info' });
            } else {
                setCursorEvent(undefined);
                document.title = 'Time2Emphasize';
            }
        },
        [setCursorEvent, messageBar]
    );

    useMemo(() => {
        // listen to sync events/layouts
        getChannels(
            sync,
            showEvent,
            (l: string) => setLayout(l),
            () => undefined, // spectator won't send updates
            ui
        );
        if (!spectatorUpdate[0]) {
            postChannel(sync.pairings['_spectator'], '{"spectator": "update"}', ui);
            spectatorUpdate[0] = true;
        }
    }, [showEvent, sync]);

    return (
        <TrackingComponent
            spectatorMode={true}
            mode={CellMode.JUMP}
            cursorEvent={cursorEvent}
            action={undefined}
            messageBar={messageBar}
            onTrack={() => undefined}
            onMode={() => undefined}
            layout={layout}
            onLayout={() => undefined}
        />
    );
}
