import { Event } from '../timeline/event';
import { TimelineTick } from '../timeline/timeline-tick';

const BREAKZOOMS = [60, 120, 240, 400, 600];

export const ONE_HOUR_MS = 3600000;
export const BACKGROUND_WIDTH = 238;

export function timeToWidth(zoom: number, ms: number): number {
    return Math.floor((ms * zoom) / ONE_HOUR_MS);
}

export function widthToTime(zoom: number, widthInPx: number) {
    return (widthInPx * ONE_HOUR_MS) / zoom;
}

export function getTime(from: number, zoom: number, x: number) {
    return from + widthToTime(zoom, x);
}

export function getLocalTimeStr(language: string, saveNow: number, time: Date) {
    let label = '';
    if (Math.abs(saveNow - time.getTime()) < 259200000) {
        if (time.getMinutes() === 0) {
            label = time.toLocaleDateString(language, { weekday: 'short' });
        } else if (time.getHours() === 0) {
            label = time.toLocaleDateString(language, { weekday: 'short' });
        }
    } else {
        if (time.getFullYear() === new Date(saveNow).getFullYear()) {
            label = time.toLocaleDateString(language, {
                day: '2-digit',
                month: '2-digit',
            });
        } else {
            label = time.toLocaleDateString(language, {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
    }
    if (time.getHours() < 10) {
        label += ' 0' + time.getHours();
    } else {
        label += ' ' + time.getHours();
    }
    if (time.getMinutes() < 10) {
        label += ':0' + time.getMinutes();
    } else {
        label += ':' + time.getMinutes();
    }
    return label;
}

export function getQuantisized(quantisize: number, time: number): number {
    const duration = time - (time % quantisize);
    if (time % quantisize >= quantisize / 2) {
        return duration + quantisize;
    }
    return duration;
}

export function getJiraDuration(hoursPerWeek: number, hoursPerDay: number, duration: number): string {
    const vals = {
        w: Math.floor(duration / (hoursPerWeek * 3600000) / 7),
        d: Math.floor(duration / (hoursPerDay * 3600000)) % 7,
        h: Math.floor(duration / 3600000) % 24,
        m: Math.floor(duration / 60000) % 60,
        s: Math.floor(duration / 1000) % 60,
        ms: duration % 1000,
    };

    let s = '';
    for (const key in vals) {
        if (vals[key] > 0) {
            s += vals[key] + key + ' ';
        }
    }
    return s;
}

export function getLeft(cursor: number, focus: number, zoom: number, widthInPx: number, current: Event) {
    return timeToWidth(zoom, current.s - cursor) + focus * widthInPx;
}

export function getDuration(saveNow: number, zoom: number, current: Event, following: Event | undefined) {
    const from = current.s;
    const until = current.e || following?.s || saveNow;
    return timeToWidth(zoom, until - from);
}

export function getCursorType(cursor: number, saveNow: number) {
    if (cursor < saveNow) {
        return 'previous';
    } else if (cursor > saveNow) {
        return 'future';
    }
    return 'now';
}

export function getBackgroundShift(cursor: number, focus: number, zoom: number, span: number): number {
    const mod = Math.floor(widthToTime(zoom, BACKGROUND_WIDTH));
    const from = cursor - focus * span;
    return timeToWidth(zoom, from % mod);
}

export function getHourShift(cursor: number, focus: number, zoom: number, span: number): number {
    const from = cursor - focus * span;

    const time = new Date();
    time.setTime(from);
    time.setMinutes(0);
    time.setSeconds(0);
    time.setMilliseconds(0);
    return timeToWidth(zoom, from - time.getTime());
}

export function getTicks(language: string, cursor: number, focus: number, zoom: number, saveNow: number, span: number): TimelineTick[] {
    const ticks = [];

    const fromTime = new Date();
    fromTime.setTime(cursor - focus * span);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    fromTime.setMilliseconds(0);
    const from = fromTime.getTime();
    const time = new Date();
    const offset = getHourShift(cursor, focus, zoom, span);

    let increment = Math.ceil(60 / (zoom + 0.01));
    if (zoom < BREAKZOOMS[0]) {
        increment *= ONE_HOUR_MS;
    } else if (zoom < BREAKZOOMS[1]) {
        increment *= ONE_HOUR_MS / 2;
    } else if (zoom < BREAKZOOMS[2]) {
        increment *= ONE_HOUR_MS / 4;
    } else if (zoom < BREAKZOOMS[3]) {
        increment *= ONE_HOUR_MS / 12;
    } else if (zoom < BREAKZOOMS[4]) {
        increment *= ONE_HOUR_MS / 30;
    } else {
        increment *= ONE_HOUR_MS / 60;
    }

    const mod = fromTime.getHours() + increment;
    for (let t = from; t <= from + span + ONE_HOUR_MS; t += increment) {
        time.setTime(t);
        const left = timeToWidth(zoom, time.getTime() - from) - offset;
        const label = getLocalTimeStr(language, saveNow, time);
        let top = 16;
        if (zoom < BREAKZOOMS[0]) {
            if (time.getHours() % 8 === mod % 8 && time.getMinutes() === 0) {
                top = 0;
            }
        } else if (zoom < BREAKZOOMS[1]) {
            if (time.getHours() % 4 === mod % 4 && time.getMinutes() === 0) {
                top = 0;
            }
        } else if (zoom < BREAKZOOMS[2]) {
            if (time.getHours() % 2 === mod % 2 && time.getMinutes() === 0) {
                top = 0;
            }
        } else if (zoom < BREAKZOOMS[3]) {
            if (time.getMinutes() === 0) {
                top = 0;
            }
        } else if (zoom < BREAKZOOMS[4]) {
            if (time.getMinutes() % 30 === 0) {
                top = 0;
            }
        } else {
            if (time.getMinutes() % 10 === 0) {
                top = 0;
            }
        }
        ticks.push({ left, label, top });
    }
    return ticks;
}
