export class Cell {
    s?: Cell[]; // split children
    l?: 'v' | 'h'; // layout
    n?: string; // name
    c?: string; // color

    _parent?: Cell;
    _containedInvalidate = true;
    _containedRows = 1;
    _containedCols = 1;

    static fromJson(json: string) {
        const obj = JSON.parse(json);
        if (obj.format !== 'time.emphasize') {
            throw new Error('unsupported format');
        }
        if (obj.v !== '2') {
            throw new Error('unsupported version ' + obj.v);
        }
        if (obj.r === undefined) {
            throw new Error('missing root');
        }
        const root = new Cell('', '');
        root.fromObj(obj.r);
        return root;
    }

    constructor(name: string, color: string) {
        if (name !== undefined && color !== undefined) {
            this.setBox(name, color);
        }
    }

    private parentRevalidateContained() {
        this._containedInvalidate = true;
        if (this._parent) {
            this._parent.parentRevalidateContained();
        } else {
            this.countContained();
        }
    }

    private countContained() {
        if (!this._containedInvalidate) {
            return [this._containedRows, this._containedCols];
        }
        if (this.s !== undefined) {
            const firstContained = this.s[0].countContained();
            const secondContained = this.s[1].countContained();
            if (this.l === 'h') {
                this._containedRows = Math.max(firstContained[0], secondContained[0]);
                this._containedCols = firstContained[1] + secondContained[1];
            } else {
                this._containedRows = firstContained[0] + secondContained[0];
                this._containedCols = Math.max(firstContained[1], secondContained[1]);
            }
        } else {
            this._containedRows = 1;
            this._containedCols = 1;
        }
        this._containedInvalidate = false;
        return [this._containedRows, this._containedCols];
    }

    public setChildren(layout: string, first: Cell, second: Cell) {
        if (!(layout === 'h' || layout === 'v')) {
            throw new Error('unsupported layout ' + layout);
        }
        this.l = layout;
        this.s = [first, second];
        this.s[0]._parent = this;
        this.s[1]._parent = this;

        this.n = undefined;
        this.c = undefined;

        this.parentRevalidateContained();
    }

    public setBox(name: string, color: string) {
        this.n = name;
        this.c = color;

        this.l = undefined;
        this.s = undefined;

        this.parentRevalidateContained();
    }

    public isSplit() {
        return this.s !== undefined;
    }

    public toJson() {
        const replacer = (key: string, value: unknown) => {
            if (['', '0', '1', 's', 'l', 'n', 'c', 'id'].indexOf(key) === -1) {
                return undefined;
            }
            return value;
        };
        return '{"v":"2","format":"time.emphasize","r":' + JSON.stringify(this, replacer) + '}';
    }

    public fromJson(json: string) {
        const obj = JSON.parse(json);
        if (obj.format !== 'time.emphasize') {
            throw new Error('unsupported format');
        }
        if (obj.v !== '2') {
            throw new Error('unsupported version ' + obj.v);
        }
        if (obj.r === undefined) {
            throw new Error('missing root');
        }
        this.fromObj(obj.r);
    }

    public fromObj(obj: unknown) {
        if (obj !== undefined) {
            if (obj['l'] !== undefined) {
                const first = new Cell('', '');
                first.fromObj(obj['s'][0]);
                const second = new Cell('', '');
                second.fromObj(obj['s'][1]);
                this.setChildren(obj['l'], first, second);
            } else {
                this.setBox(obj['n'], obj['c']);
            }
        }
    }
}
