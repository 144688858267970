import React from 'react';

const delay = 500;

export function useIdleRefocus() {
    const [idleRefocus, setIdleRefocus] = React.useState<NodeJS.Timeout>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();

        const target = document.activeElement as HTMLInputElement;
        if (idleRefocus) {
            clearTimeout(idleRefocus);
        }

        const newTimeout = setTimeout(() => {
            target.blur();
            target.focus();

            if (newTimeout === idleRefocus) {
                setIdleRefocus(null);
            }
        }, delay);

        setIdleRefocus(newTimeout);
    };

    const clearIdle = () => {
        if (idleRefocus) {
            clearTimeout(idleRefocus);
            setIdleRefocus(null);
        }
    };

    return { onChange, clearIdle };
}
