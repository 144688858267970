export const KEY_LAYOUT = 'layout';
export const KEY_LEGALS_SEEN = 'legals-seen';
export const KEY_THEME_MODE = 'theme-mode';
export const KEY_CONNECTIONS = 'sync';
export const KEY_REPORT_GROUP = 'reporting-group';
export const KEY_REPORT_QUANTISIZE = 'reporting-quantisize';
export const KEY_REPORT_INFOS = 'reporting-infos';
export const KEY_REPORT_COLORS = 'reporting-colors';
export const KEY_TIMELINE_ZOOM = 'timeline-zoom';
export const KEY_USER_ID = 'ui';
