import { MouseEvent } from 'react';

export function getSplitSection(event: MouseEvent<HTMLElement>): string {
    const target = event.currentTarget as unknown;
    const w = target['offsetWidth'] / 2;
    const h = target['offsetHeight'] / 2;
    const x = (event.nativeEvent.offsetX - w) / w;
    const y = (event.nativeEvent.offsetY - h) / h;

    if (x < y) {
        if (-x < y) {
            return 'bottom';
        } else {
            return 'left';
        }
    } else {
        if (-x < y) {
            return 'right';
        } else {
            return 'top';
        }
    }
}
