import { Event } from '../timeline/event';
import { domain } from '../utils/domain';
import { groupBy } from '../utils/group-by';
import { ui } from '../utils/storage-impl';

import { ConnectionMode } from './connection-mode';
import { checkContinue, initLatest, updateLatest } from './connection-utils';
import { Pairing } from './pairing';
import { Sync } from './sync';

const IDLE_MS_BETWEEN_SYNCS = 500;

const latest = initLatest();
const timeouts: Record<string, NodeJS.Timeout> = {};

export function getChannels(
    sync: Sync,
    onEvent: (event: Event, userEvent: boolean) => void,
    onLayout: (layout: string, userEvent: boolean) => void,
    onUpdate: (connectionMode: ConnectionMode) => void,
    u: string | undefined = undefined
) {
    updateLatest(latest, sync, onEvent, onLayout, onUpdate);

    const channels = groupBy(
        Object.keys(sync.pairings)
            .filter(
                (k) =>
                    [ConnectionMode.QRCODE_PUSH, ConnectionMode.SAME_USER, ConnectionMode.SPECTATOR, ConnectionMode.PEEP].includes(
                        sync.pairings[k].sync
                    ) && sync.pairings[k].active
            )
            .map((k) => sync.pairings[k]),
        (p) => p.channel
    );
    Object.keys(channels).forEach((k) =>
        poll(
            sync.version,
            0,
            k,
            channels[k].map((p) => p.pairing),
            u
        )
    );
}

async function poll(version: number, idle: number, channel: string, pairings: string[], u: string | undefined) {
    if (!checkContinue(latest, version, pairings)) {
        return;
    }
    if (timeouts[channel]) {
        clearTimeout(timeouts[channel]);
        delete timeouts[channel];
    }

    const newTimeout = setTimeout(
        async () => {
            if (timeouts[channel] === newTimeout) {
                let extraIdle = 0;
                const start = new Date().getTime();
                try {
                    const res = await fetch(`${domain}/channel/?i=${channel}&u=${u ?? ui()}&o=${pairings.join(',')}`); // TODO only open when not in spectatorMode
                    const body = await res.json();
                    if (body && Array.isArray(body)) {
                        const data = body as Array<unknown>;
                        data.forEach((value) => {
                            if (value['_ui'] !== (u ?? ui())) {
                                delete value['_ui'];
                                if (value['s']) {
                                    latest.onEvent(value as Event, false);
                                } else {
                                    if (value['spectator'] === 'update') {
                                        latest.onUpdate(ConnectionMode.SPECTATOR);
                                    } else if (value['peep'] === 'update') {
                                        latest.onUpdate(ConnectionMode.PEEP);
                                    } else {
                                        latest.onLayout(JSON.stringify(value), false);
                                    }
                                }
                            }
                        });
                    }

                    if (res.status > 204) {
                        extraIdle = 15000 - (new Date().getTime() - start);
                    }
                } catch (e) {
                    extraIdle = 15000 - (new Date().getTime() - start);
                }
                poll(version, extraIdle, channel, pairings, u);
            }
        },
        Math.max(IDLE_MS_BETWEEN_SYNCS, idle)
    );

    timeouts[channel] = newTimeout;
}

export async function postChannel(pairing: Pairing, e: Event | string, u: string | undefined = undefined) {
    await fetch(`${domain}/channel/?i=${pairing.channel}&p=${pairing.pairing}&u=${u ?? ui()}`, {
        body: e['s'] ? JSON.stringify(e) : (e as string),
        method: 'POST',
        keepalive: true,
    });
}
