import React from 'react';

export function useDelayed(delayMs = 500) {
    const [timeOut, setTimeOut] = React.useState<NodeJS.Timeout>(null);

    const onDelay = (f: () => void) => {
        if (timeOut) {
            clearTimeout(timeOut);
        }

        const newTimeout = setTimeout(() => {
            f();

            if (newTimeout === timeOut) {
                setTimeOut(null);
            }
        }, delayMs) as unknown as NodeJS.Timeout;

        setTimeOut(newTimeout);
    };

    const clearDelay = () => {
        if (timeOut) {
            clearTimeout(timeOut);
            setTimeOut(null);
        }
    };

    return { onDelay, clearDelay };
}
