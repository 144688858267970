import ClearIcon from '@mui/icons-material/Clear';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ComponentProps = {
    info: string;
    setInfo: (info: string) => void;
    onInfo: (info: string) => void;
    onClose: () => void;
};
export function Info({ info, setInfo, onInfo, onClose }: ComponentProps) {
    const { t } = useTranslation();

    return (
        <FormControl fullWidth variant="outlined" color="secondary">
            <InputLabel htmlFor="outlined-adornment-info" color="secondary">
                {t('info')}
            </InputLabel>
            <OutlinedInput
                id="outlined-adornment-info"
                autoFocus
                value={info}
                data-testid="info.input"
                onChange={(event) => setInfo(event.target.value.replaceAll('\t', ' '))}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onInfo(info);
                        onClose();
                        event.preventDefault();
                    } else if (event.key === 'Escape') {
                        onClose();
                        event.preventDefault();
                    }
                }}
                endAdornment={
                    <InputAdornment position="end">
                        {!!info.length && (
                            <Tooltip arrow title={t('info.erase')}>
                                <IconButton aria-label={t('info.erase')} data-testid="info.clear" onClick={() => setInfo('')} edge="end">
                                    <ClearIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip arrow title={t('info.set')}>
                            <IconButton
                                aria-label={t('info.set')}
                                data-testid="info.set"
                                onClick={() => {
                                    onInfo(info);
                                    onClose();
                                }}
                                edge="end"
                            >
                                <TextsmsIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                }
                label={t('info')}
            />
        </FormControl>
    );
}
